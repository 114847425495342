/**
 * External Dependencies
 */
 import React, { Component } from "react"
 import ReactAudioPlayer from "react-audio-player"
 import lottie from "lottie-web"

 /**
  * Internal Dependencies
  */
 import Animation from "./Animation"
//  import soundOn from "../../content/assets/sound/sound-on.svg"
//  import soundOff from "../../content/assets/sound/sound-off.svg"

 export default class BackgroundAudio extends Component {
   constructor(props) {
     super(props)

     this.togglePlay = this.togglePlay.bind(this)

     this.state = {
       playerInstance: null,
       isPlaying: props.playing,
       muted: props.muted,
       playerRef: React.createRef(),
       animationContainer: React.createRef(),
       lottieButton: null,
     }
   }

   componentDidMount() {
    const { animationContainer } = this.state

    // const path = 'https://maxst.icons8.com/vue-static/landings/animated-icons/icons/pause/pause.json'
    // const path = 'https://assets3.lottiefiles.com/datafiles/xBp1xc9QwYcufe3p7wVhoeZtUdWf3K3XyjYKewB7/ModernPictogramsForLottie_LoudMute.json'
    const path = 'https://assets10.lottiefiles.com/packages/lf20_0pphijzk.json'
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path,
    })

    anim.goToAndStop(0, true);

    this.setState({
      lottieButton: anim
    })
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      const { lottieButton } = this.state
      lottieButton.destroy()
    }
  }

  togglePlay() {
    const { playerRef, lottieButton } = this.state

    if (playerRef.current.audioEl.current.paused) {
      this.setState({isPlaying: true})
      lottieButton.playSegments([0, 58], true);
      playerRef.current.audioEl.current.play()
    } else {
      this.setState({isPlaying: false})
      lottieButton.playSegments([59, 88], true);
      playerRef.current.audioEl.current.pause()
    }
   }

   render() {
     const { children, src } = this.props
     const { isPlaying, playerRef, animationContainer, muted } = this.state

    //  console.log('muted', muted, isPlaying)
     return (
      <>
        <ReactAudioPlayer
          ref={playerRef}
          autoPlay={isPlaying}
          muted={muted}
          loop
          src={src}
        />
        <button
          type="button"
          className="btn rounded-pill audio-toggle-button"
          onClick={this.togglePlay}
        >
          {/* <Animation path="https://assets6.lottiefiles.com/packages/lf20_9xrb83ya.json" /> */}
          <div className="animation-container" ref={animationContainer} />
          {/* <div className="animation-container" style={{marginTop: '-35px'}}>
            <img src={soundOn} alt="Back to Top" />
          </div> */}
          {/* {(!!isPlaying) && (<img src={soundOff} alt="Sound Off" />)}
          {(isPlaying === undefined || !isPlaying) && (<img src={soundOn} alt="Sound On" />)} */}
        </button>
        <>{children}</>
      </>
     )
   }
 }
