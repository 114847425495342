/**
 * External Dependencies
 */
import React, { Component } from "react"
import Player from "@vimeo/player"
import lottie from "lottie-web"

/**
 * Internal Dependencies
 */
import Animation from "./Animation"
import soundOn from "../../content/assets/sound/sound-on.svg"
import soundOff from "../../content/assets/sound/sound-off.svg"

export default class BackgroundVideo extends Component {
  constructor(props) {
    super(props)

    this.toggleMuted = this.toggleMuted.bind(this)
    this.updateIframeDimensions = this.updateIframeDimensions.bind(this)

    this.state = {
      minHeight: "100vh",
      minWidth: `177.777vh`,
      playerInstance: null,
      muted: true,
      playerRef: React.createRef(),
      animationContainer: React.createRef(),
      lottieButton: null,
    }
  }

  componentDidMount() {
    const { playerRef, animationContainer } = this.state

    this.updateIframeDimensions()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.updateIframeDimensions)
    }

    // Couldn't figure out how get references from a chile component
    // Dropping animation in here directly so we can reference it
    // const path = 'https://maxst.icons8.com/vue-static/landings/animated-icons/icons/pause/pause.json'
    // const path = 'https://assets3.lottiefiles.com/datafiles/xBp1xc9QwYcufe3p7wVhoeZtUdWf3K3XyjYKewB7/ModernPictogramsForLottie_LoudMute.json'
    const path = 'https://assets10.lottiefiles.com/packages/lf20_0pphijzk.json'
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path,
    })

    anim.goToAndStop(0, true);

    this.setState({
      playerInstance: new Player(playerRef.current, {
        autoplay: true,
        allowFullScreen: true,
        background: true,
        loop: true,
        byline: true,
        title: true,
        muted: true,
      }),
      muted: true,
      lottieButton: anim
    })
  }

  componentDidUpdate() {
    const { playerInstance } = this.state

    if (playerInstance !== null) {
      playerInstance
        .ready()
        .then(() => {
          // eslint-disable-next-line no-console
          // console.log("VideoBackground::ready", playerInstance)
          playerInstance.play().then(() => {
            // eslint-disable-next-line no-console
            // console.log("VideoBackground::play", playerInstance)
          })
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          // console.log("VideoBackground::error", error)
        })
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateIframeDimensions)

      const { lottieButton } = this.state
      lottieButton.destroy()
    }
  }

  updateIframeDimensions() {
    if (typeof window !== "undefined") {
      this.setState({
        minHeight: `${window.innerHeight}px`,
        minWidth: `${Math.round(window.innerHeight * 1.77777)}px`,
      })
    }
  }

  async toggleMuted() {
    const { playerInstance, lottieButton } = this.state

    await playerInstance.ready()

    const muted = await playerInstance.getMuted()

    this.setState({
      muted: !muted
    })
    // eslint-disable-next-line no-console
    // console.log("muted", muted)
    if (muted) {
      await playerInstance.setMuted(false)

      // eslint-disable-next-line no-console
      // console.log("Unmuted")
      const result = await playerInstance.setVolume(1)
      lottieButton.playSegments([0, 58], true);

      // eslint-disable-next-line no-console
      // console.log("Volume set to", result)
    } else {
      const volume = await playerInstance.getVolume()
      const result = await playerInstance.setVolume(volume === 0 ? 1 : 0)
      // console.log('muted!')
      lottieButton.playSegments([59, 88], true);

      // eslint-disable-next-line no-console
      // console.log("Volume set to", result)
    }
  }

  render() {
    const { children, src } = this.props
    const { minHeight, minWidth, playerRef, muted, animationContainer } = this.state

    return (
      <div
        className="container-fluid has-video-background"
        style={{
          color: "white",
          height: "100vh",
          position: "relative",
        }}
      >
        <div className="container d-flex flex-column h-100 align-items-center justify-content-center">
          <div className="video-wrapper">
            <iframe
              className="video-iframe embed-responsive-item"
              ref={playerRef}
              id="player"
              src={`https://player.vimeo.com/video/${src}?api=1&amp;autoplay=1&amp;background=1&amp;loop=1&amp;byline=0&amp;title=0$amp;muted=1`}
              title="Vimeo"
              frameBorder="0"
              allow="autoplay"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              style={{ minHeight, minWidth }}
            />
          </div>
          <button
            type="button"
            className="btn rounded-pill audio-toggle-button bg-video-button"
            onClick={this.toggleMuted}
          >
            {/* {muted && <Animation path="https://assets6.lottiefiles.com/packages/lf20_jbh3twry.json" /> */}
            {/* {!muted && <Animation path="https://assets10.lottiefiles.com/packages/lf20_9xrb83ya.json" />} */}
            {/* <Animation path="https://assets6.lottiefiles.com/packages/lf20_9xrb83ya.json" /> */}
            <div className="animation-container" ref={animationContainer} />
            {/* {(!!muted) && (<img src={soundOff} alt="Sound Off" />)}
            {(muted === undefined || !muted) && (<img src={soundOn} alt="Sound On" />)} */}
          </button>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
              // zIndex: 1,
              // overflow: "scroll",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    )
  }
}
