/**
 * External Dependencies
 */
import React, { useEffect, useState } from 'react'
import Scroll from 'react-scroll'

/**
 * Internal Dependencies
 */
import backToTop from '../../content/assets/arrows/back-to-top.svg'

// eslint-disable-next-line react/function-component-definition
export default function BackToTop() {
  const scroll = Scroll.animateScroll

  // useState and useEffect to do this
  // Is this the best way? Looks to be after a it of googling
  const [iconHasShown, setIconHasShown] = useState(false)
  const [showIcon, setShowIcon] = useState('')
  const [topPos, setTopPos] = useState('auto')

  useEffect(() => {
    const handleScroll = event => {
      const yPos = window.scrollY
      // use height of <article> rather than body
      // because body doesn't work properly with video backgrounds
      let defaultPosition = 25
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight

      let limit = 0

      // figure out if there is a more stories component
      const moreStories = document.getElementsByClassName(
        'component-more-stories'
      )

      function getOffset(el) {
        const rect = el.getBoundingClientRect()
        return {
          left: rect.left + window.scrollX,
          top: rect.top + window.scrollY,
        }
      }

      const footer = document.getElementsByClassName('page-footer')
      if (moreStories.length) {
        const pos = getOffset(moreStories[0])
        limit = parseInt(pos.top, 10)
      } else {
        const pos = getOffset(footer[0])
        limit = parseInt(pos.top, 10)
      }

      if (yPos + windowHeight > limit) {

        if (windowWidth < 576) {
          // On mobile if we have video background or audio player
          // We need to set a different offset/height position for back-to-top
          // when scrolling near the bottom of the page
          // otherwise it will sit over the play/mute sound button
          const socialLinks = document.getElementsByClassName('social-links')
          const pos = getOffset(socialLinks[0])

          if (yPos + windowHeight > parseInt(pos.top, 10) + 50) {
            const videoWrapper =
              document.getElementsByClassName('video-wrapper')
            const audioPlayer = document.getElementsByClassName(
              'react-audio-player '
            )
            if (videoWrapper.length || audioPlayer.length) {
              defaultPosition = 0
            }
          }
        }
        setTopPos(`${yPos + windowHeight - limit + defaultPosition}px`)
      } else if (yPos > windowHeight) {
        setShowIcon(true)
        setIconHasShown(true)
        setTopPos(`${defaultPosition}px`)
      } else if (iconHasShown) {
        // Use flag to indicate if scrolled
        // so the icon doesn't fade in on page load
        setShowIcon(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const scrollTop = () => {
    const options = {}
    scroll.scrollToTop(options)
  }

  return (
    <div
      className="back-to-top-wrapper"
      data-show={showIcon}
      style={{ bottom: topPos }}
    >
      <button
        type="button"
        onClick={scrollTop}
        className="btn rounded-pill back-to-top"
      >
        <img src={backToTop} alt="Back to Top" />
      </button>
    </div>
  )
}
