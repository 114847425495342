/**
 * External Dependencies
 */
import React, { useEffect, createRef } from "react"
import lottie from "lottie-web"

// import animationData from "../../content/assets/animations/scroller.json"

const Animation = ({ path, title }) => {
  const animationContainer = createRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      // animationData,
      path,
    })
    return () => anim.destroy() // optional clean up for unmounting
  }, [animationContainer, path])

  return (
    <div
      aria-label={title}
      className="animation-container"
      ref={animationContainer}
      title={title}
    />
  )
}

export default Animation
