/**
 * External Dependencies
 */
import React, { Component } from "react"

/**
 * Internal Dependencies
 */

export default class BackgroundGradient extends Component {
  constructor(props) {
    super(props)

    this.state = {
      minHeight: "100vh",
      minWidth: `177.777vh`,
    }
  }

  componentDidMount() {
    this.updateIframeDimensions()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.updateIframeDimensions)
    }
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateIframeDimensions)
    }
  }

  updateIframeDimensions() {
    if (typeof window !== "undefined") {
      this.setState({
        minHeight: `${window.innerHeight}px`,
        minWidth: `${Math.round(window.innerHeight * 1.77777)}px`,
      })
    }
  }

  render() {
    const { children, gradientClassName } = this.props
    const { minHeight, minWidth, playerRef } = this.state

    // add slug as classname so css gradient can target this element
    const wrapperClassName = `video-wrapper ${gradientClassName}`

    return (
      <div
        className="container-fluid has-video-background"
        style={{
          color: "white",
          height: "100vh",
          position: "relative",
        }}
      >
        <div className="container d-flex flex-column h-100 align-items-center justify-content-center">
          <div className={wrapperClassName}>
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
              zIndex: 1,
              // overflow: "scroll",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    )
  }
}
