/**
 * External Dependencies
 */
import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import shortid from "shortid"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import { Fade as Hamburger } from "hamburger-react"

/**
 * Internal Dependencies
 */
import Animation from "./Animation"
import MainMenuContent from "./MainMenuContent"

export default class MainMenu extends React.Component {
  constructor(props) {
    super(props)

    this.targetRef = React.createRef()
    // this.targetElement = null

    this.toggleMenu = this.toggleMenu.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.state = {
      collapsed: true,
    }
  }

  componentDidMount() {
    // 3. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav).
    // Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
    // This is also the element to apply the CSS '-webkit-overflow-scrolling: touch;' if desired.
    // enableBodyScroll(this.targetRef.current)
    // this.targetElement = this.targetRef
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  toggleMenu() {
    const { collapsed } = this.state

    if (collapsed) {
      this.openMenu()
    } else {
      this.closeMenu()
    }
  }

  openMenu() {
    this.setState({
      collapsed: false,
    })

    disableBodyScroll(this.targetRef.current)
  }

  closeMenu() {
    this.setState({
      collapsed: true,
    })

    enableBodyScroll(this.targetRef.current)
  }

  render() {
    const { settings } = this.props
    const { collapsed } = this.state

    return (
      <nav
        id="navbar"
        ref={this.targetRef}
        className={classNames({
          navbar: true,
          "bg-transparent": collapsed,
        })}
      >
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <Animation path={settings.logo.url} title={settings.logo.title} />
          </Link>

          <div className="hamburger">
            <Hamburger
              color="#FFFFFF"
              direction="left"
              distance="lg"
              size={28}
              toggle={this.toggleMenu}
              toggled={!collapsed}
            />
          </div>

          <div
            className={classNames({
              "navbar-collapse text-center": true,
              collapse: collapsed,
            })}
          >
            <MainMenuContent settings={settings} />
          </div>
        </div>
      </nav>
    )
  }
}
