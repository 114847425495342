/**
 * External Dependencies
 */
import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import shortid from 'shortid'

/**
 * Internal Dependencies
 */

export const LOCALE_ERROR_MESSAGE = 'No Locale Menu'
export const MENU_ERROR_MESSAGE = 'No Menu items'

export const ButtonLink = ({ href, children }) => (
  <div className="wp-block-button is-style-outline">
    {/* Do not use <Link> as we can have external links here */}
    <a href={href} className="wp-block-button__link has-white-color">
      {children}
    </a>
  </div>
)

export const MainMenuLinks = ({ locale, data }) => {
  const localeContent = data.filter(item => item.locale === locale).shift()

  const menuHasItems = items => Array.isArray(items) && items.length > 0 && items[0]

  if (!localeContent) {
    return <div>{LOCALE_ERROR_MESSAGE}</div>
  }

  if (!menuHasItems(localeContent.mainMenu)) {
    return <div>{MENU_ERROR_MESSAGE}</div>
  }

  const menu = localeContent.mainMenu
  const buttons = localeContent.mainMenuButtons
  const languages = localeContent.mainMenuLanguages

  return (
    <div>
      <ul className="navbar-nav" id="main-menu">
        {/* Site sections/pages (e.g. "Inspire", "About Us") */}
        {menu.map(item => (
          <li className="nav-item" key={shortid.generate()}>
            {/* use <Link> as should be ALL internal links */}
            <Link to={item.href} className="nav-link">
              {item.text}
            </Link>
          </li>
        ))}

        {/* Buttons (e.g. "Find a Dealer") */}
        {menuHasItems(buttons) && (
          <li className="menu-buttons">
            <ul className="list-unstyled">
              {buttons.map(item => (
                <li key={shortid.generate()}>
                  <ButtonLink href={item.href}>{item.text}</ButtonLink>
                </li>
              ))}
            </ul>
          </li>
        )}

        {/* Language selector (e.g. "EN | FR" for Canada) */}
        {menuHasItems(languages) && (
          <li className="menu-languages">
            <ul className="list-unstyled">
              {languages.map(item => (
                <li key={shortid.generate()}>
                  {/* Do not use <Link> as we can have external links here */}
                  <a href={item.href}>{item.text}</a>
                </li>
              ))}
            </ul>
          </li>
        )}
      </ul>
    </div>
  )
}

export default function MainMenuContent({ settings }) {
  const {
    wp: { mazdaStoriesLocaleContent },
  } = useStaticQuery(
    graphql`
      query {
        wp {
          mazdaStoriesLocaleContent {
            locale
            mainMenu {
              href
              text
            }
            mainMenuButtons {
              href
              text
            }
            mainMenuLanguages {
              href
              text
            }
          }
        }
      }
    `
  )

  const currentLocale = process.env.LOCALE
  return <MainMenuLinks locale={currentLocale} data={mazdaStoriesLocaleContent} />
}
