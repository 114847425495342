/**
 * External Dependencies
 */
import React from "react"
import { setConfig } from "react-hot-loader"
import { useStaticQuery, graphql } from "gatsby"
import SwiperCore, { Scrollbar, Navigation, Pagination } from "swiper"
import {
  Cookies,
  CookiesProvider,
  CookieBannerUniversal,
} from "react-cookie-banner"

/**
 * Internal Dependencies
 */
import BackToTop from "./BackToTop"
import Footer from "./Footer"
import MainMenu from "./MainMenu"
import BackgroundAudio from "./BackgroundAudio"
import BackgroundGradient from "./BackgroundGradient"
import BackgroundVideo from "./BackgroundVideo"

// Sass Styling
import "../sass/main.scss"

setConfig({ pureSFC: true, reloadLifeCycleHooks: true })

// Init Swiper scrollbar here so we can use it in child components
SwiperCore.use([Scrollbar, Navigation, Pagination])

const cookies = new Cookies()

const Layout = ({ isHomePage, is404Page, children, settings, post }) => {
  const {
    wp: {
      generalSettings: { title, description },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  // const { slug, guid } = post || {}
  const { slug } = post || {}

  // console.log(post)
  // console.log("slug", slug)
  // console.log("guid", guid)
  // console.log("children", children)
  // console.log("settings", settings)

  const mainMenuSettings = {
    sections: !!settings && settings.mainMenu ? settings.mainMenu : null,
    buttons:
      !!settings && settings.mainMenuButtons ? settings.mainMenuButtons : null,
    languages:
      !!settings && settings.languagesMenu ? settings.languagesMenu : null,
    logo: !!settings && settings.logo ? settings.logo : null,
  }

  const footerSettings = {
    menu: !!settings && settings.footerMenu ? settings.footerMenu : null,
    social: !!settings && settings.socialMenu ? settings.socialMenu : null,
    logoUrl: !!settings && settings.footerLogo ? settings.footerLogo : null,
    tagLine:
      !!settings && settings.footerTagLine ? settings.footerTagLine : null,
  }

  const postBackgroundMedia = (post?.backgroundMedia) ? post.backgroundMedia.split('|').map(item => item.trim()) : [];

  // Default to JSX Fragment Wrapper
  // eslint-disable-next-line react/destructuring-assignment
  let Wrapper = props => <>{props.children}</>

  // Does the YAML file have a Background Audio for this slug (URL)?
  const audio = (settings?.backgroundAudio || []).find(x => x?.slug === slug)

  if (audio) {
    // BackgroundAudio Wrapper
    Wrapper = props => (
      <BackgroundAudio
        src={audio.src}
        playback={{
          playing: false,
          muted: false,
        }}
      >
        {props.children}
      </BackgroundAudio>
    )
  }
  if (postBackgroundMedia[0] === 'audio') {
    // if post has backgroundMedia audio|url
    // console.log('IN HERE', postBackgroundMedia)
    Wrapper = props => (
      // eslint-disable-next-line react/destructuring-assignment
      <BackgroundAudio
        src={postBackgroundMedia[1]}
        playback={{
          playing: false,
          muted: false,
        }}
      >
        {props.children}
      </BackgroundAudio>
    )
  }

  // Does the YAML file have a Background Video for this slug (URL)?
  const video = (settings?.backgroundVideo || []).find(x => x?.slug === slug)

  if (video) {
    // BackgroundVideo Wrapper
    Wrapper = props => (
      // eslint-disable-next-line react/destructuring-assignment
      <BackgroundVideo src={video.src}>{props.children}</BackgroundVideo>
    )
  }
  if (postBackgroundMedia[0] === 'video') {
    // console.log('IN HERE VIDEO', postBackgroundMedia)
    // if post has backgroundMedia video|vimeoID
    Wrapper = props => (
      // eslint-disable-next-line react/destructuring-assignment
      <BackgroundVideo src={postBackgroundMedia[1]}>{props.children}</BackgroundVideo>
    )
  }

  const gradient = (settings?.backgroundGradient || []).find(x => x?.slug === slug)


  if (gradient) {
    // BackgroundVideo Wrapper
    Wrapper = props => (
      // eslint-disable-next-line react/destructuring-assignment
      <BackgroundGradient src={gradient.src} slug={gradient.slug}>{props.children}</BackgroundGradient>
    )
  }
  if (postBackgroundMedia[0] === 'gradient') {
    // if post has backgroundMedia gradient|className
    Wrapper = props => (
      // eslint-disable-next-line react/destructuring-assignment
      <BackgroundGradient gradientClassName={postBackgroundMedia[1]}>{props.children}</BackgroundGradient>
    )
  }

  return (
    <Wrapper>
      {settings?.cookieBanner?.enabled && (
        <CookiesProvider cookies={cookies}>
          <CookieBannerUniversal
            buttonMessage={settings?.cookieBanner?.buttonMessage}
            disableStyle
            dismissOnScroll
            dismissOnClick={false}
            message={settings?.cookieBanner?.message}
            link={
              <a href={settings?.cookieBanner?.linkUrl}>
                {settings?.cookieBanner?.linkMessage}
              </a>
            }
            cookie={settings?.cookieBanner?.cookie}
          />
        </CookiesProvider>
      )}

      <MainMenu settings={mainMenuSettings} />

      <div data-is-root-path={isHomePage} data-is-404={is404Page}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <main>{children}</main>
            </div>
          </div>
        </div>
      </div>

      {/* if wanted only on article pages, but now it should be on every page */}
      {/* {slug && (<BackToTop />)} */}
      <BackToTop />

      <Footer settings={footerSettings} />
    </Wrapper>
  )
}

export default Layout
