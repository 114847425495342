/**
 * External Dependencies
 */
import React from 'react'
import shortid from 'shortid'
import { useStaticQuery, graphql } from 'gatsby'
import sanitizeHtml from 'sanitize-html'
import { findLocaleContentFromList } from '../utils/find-locale-content-from-list'

/**
 * Internal Dependencies
 */
import mazda from '../../static/mazda-logo.png'
import facebook from '../../content/assets/social/facebook.svg'
import instagram from '../../content/assets/social/instagram.svg'
import twitter from '../../content/assets/social/twitter.svg'
import youtube from '../../content/assets/social/youtube.svg'

export const ERROR_MESSAGE = 'No Footer Data'

export const SocialLink = ({ href, text }) => {
  let img

  switch (text.toLowerCase()) {
    case 'facebook':
      img = <img src={facebook} alt={text} />
      break
    case 'youtube':
      img = <img src={youtube} alt={text} />
      break
    case 'twitter':
      img = <img src={twitter} alt={text} />
      break
    case 'instagram':
      img = <img src={instagram} alt={text} />
      break
    default:
      return <span />
  }

  return (
    <a href={href} target="_blank" rel="noreferrer">
      {img}
    </a>
  )
}

export const TagLine = ({ className, html }) => (
  <p
    className={className}
    dangerouslySetInnerHTML={{
      __html: sanitizeHtml(html, {
        allowedAttributes: {
          a: ['href', 'class'],
          span: ['class'],
        },
      }),
    }}
  />
)

export const FooterContent = ({ locale, data }) => {
  const localeContent = findLocaleContentFromList(locale, data)

  if (!localeContent) {
    return <div>{ERROR_MESSAGE}</div>
  }

  return (
    <footer className="page-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            {!!localeContent.footerTagLine && (
              <TagLine className="tagline" html={localeContent.footerTagLine} />
            )}

            {!!localeContent.footerSubTagLine && (
              <TagLine className="sub-tagline" html={localeContent.footerSubTagLine} />
            )}
          </div>
          <div className="col-md-3 offset-md-2">
            <ul className="external-links">
              {localeContent?.footerMenu?.map(item => (
                <li key={shortid.generate()}>
                  <a href={item.href} target="_blank" rel="noreferrer">
                    {item.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <ul className="social-links">
              {localeContent?.socialMenu?.map(link => (
                <li className="list-inline-item" key={shortid.generate()}>
                  <SocialLink key={shortid.generate()} href={link.href} text={link.text} />
                </li>
              ))}
            </ul>
          </div>
          <div className="col-3">
            <div className="footer-logo">
              <a href={localeContent?.footerLogoLink}>
                <img src={mazda} alt="Mazda" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default function Footer({ settings }) {
  const {
    wp: { mazdaStoriesLocaleContent },
  } = useStaticQuery(
    graphql`
      query {
        wp {
          mazdaStoriesLocaleContent {
            footerLogoLink
            footerSubTagLine
            footerTagLine
            locale
            footerMenu {
              href
              text
            }
            socialMenu {
              href
              text
            }
          }
        }
      }
    `
  )

  const currentLocale = process.env.LOCALE

  return <FooterContent locale={currentLocale} data={mazdaStoriesLocaleContent} />
}
